import React from 'react';
import "./Button.scss";
import { Link } from 'gatsby';

const Button = (originalProps) => {
    let props = originalProps.blok ? originalProps.blok : originalProps;
    let style = props.variant ? "button--" + props.variant : "button--primary"; 

    if (props.type) {
        if (props.type === "link") {
            let buttonURL = props.url;
            if(typeof props.url === "object")
            buttonURL = props.url.linktype === "story" ? `/${props.url.cached_url}` : props.url.url;
                
            return <Link to={buttonURL} className={`button ${style}`}>{props.title}{props.children}</Link>;
        }
    }

    return (
        <button
            className={`button ${style}`}
            onClick={props.onClick}
            type={props.type ? props.type : ""}
        >
            {props.title}
            {props.children}
        </button>
    );
};

export default Button;