import React from 'react';
import { Link } from "gatsby";

import Logo from '../logo/Logo';
import Button from '../button/Button';

import { navigation as navigationStyle, mainNav, toggleMenu, actionButton, show, menuEnd, closeHamburger } from "./navigation.module.scss";

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleState: false,
            toggleInit: false 
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(event) {
        let toggleState = this.state.toggleState;

        this.setState({
            toggleState: !toggleState,
            toggleInit: true
        });
    }

    render() {
        let toggleState = this.state.toggleState;
        let toggleInit = this.state.toggleInit;

        let menuClass = `${toggleInit ? (toggleState ? show : "") : ""} ${mainNav}`;
        let actionButtonClass = ` ${toggleInit ? (toggleState ? menuEnd : "") : ""} ${actionButton}`;

        return (
            <nav className={navigationStyle}>
                <Link to="/"><Logo /></Link>
                <div className={menuClass}>
                    <ul>
                        <li><Link to="/features" activeClassName={"active"}>Features</Link ></li>
                        <li><Link to="/pricing" activeClassName={"active"}>Pricing</Link ></li>
                        <li><Link to="/about" activeClassName={"active"}>About</Link ></li>
                    </ul>
                </div>
                <div className={actionButtonClass}>
                    <Button type="link" url="/contact" title="Get in touch" variant="primary" />
                </div>
                <div className={toggleMenu}>
                    <button onClick={this.handleToggle} className={toggleState ? closeHamburger : "" }><span>-</span><span>-</span><span>-</span></button>
                </div>
            </nav>
        );
    }
}

export default Navigation