/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";

import "./Layout.scss";
import Navigation from "../navigation/Navigation";
import Footer from "../footer/Footer";

const Layout = ({ children }) => {

  return (
    <>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </>
  )
};

export default Layout
