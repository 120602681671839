import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { desktopLogo, mobileLogo } from "./Logo.module.scss";

const Logo = () => {
    return (
        <>
            <div className={desktopLogo}>
                <StaticImage
                    src="../../images/Scaffold-logo-black.svg"
                    alt="Scaffold."
                />
            </div>
            <div className={mobileLogo}>
                <StaticImage
                    src="../../images/Scaffold-logo-symbol.svg"
                    alt="Scaffold."
                />
            </div>
        </>
    );
};

export default Logo;