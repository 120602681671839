import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Button from "../button/Button"

import { footer as footerStyle, main, logo as logoStyle, quickLinks, linkList, signup, formNone,  metaInfo } from "./footer.module.scss";

const Footer = () => {
    return (
        <footer className={footerStyle}>
            <div className={main}>
                <div className={logoStyle}>
                    <StaticImage
                        src="../../images/Scaffold-logo-white.svg"
                        alt="Scaffold."
                    />
                </div>
                <div className={quickLinks}>
                    <div className={linkList}>
                        <h2>Scaffold</h2>
                        <ul>
                            <li><Link to="/features">Features</Link></li>
                            <li><Link to="/pricing">Pricing</Link></li>
                        </ul>
                    </div>
                    <div className={linkList}>
                        <h2>About</h2>
                        <ul>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/contact">Get in touch</Link></li>
                        </ul>
                    </div>
                </div>
                <div className={`${signup} ${formNone}`}>
                    <form>
                        <label>Stay up to date with the latest from Scaffold.
                            <input type="text" placeholder="Enter your email" />
                            <Button type="submit" variant="primary_dark" title="Sign up" />
                        </label>
                    </form>
                </div>
            </div>
            <div className={metaInfo}>
                <p>© {new Date().getFullYear()} Curio Group Pty Ltd, Terms, Privacy Policy</p>
            </div>
        </footer>
    )
};

export default Footer;